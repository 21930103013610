
.campaign-name-content{

}

.product-name {
    list-height 36.8px
    @media screen and (min-width: 1024px){
        line-height: 43px;
        margin: 4px 0
    }
}

.label-info {
    margin-right: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.36px;
    color: rgba(18, 18, 18, 0.75);
    white-space: nowrap;

    @media screen and (max-width: 1024px){
        font-weight: 500;
        color: #6e6e73;
        font-size: .625rem;
        line-height: 1.6875rem;
    }
}

.badge-container {
    display: flex;
   gap: 8px;
}

.badge-new {
    color: #BF4800;
}
.badge-pre-order {
    color: #336e7b;
}
